import { Teammember } from "shared-components/src/models/Teammember";
import { AppState } from ".";
import UserInfo from "shared-components/src/models/UserInfo";
import {
  SET_USER_INFO,
  OPEN_QUICK_LOGIN_DIALOG,
  CLOSE_QUICK_LOGIN_DIALOG,
  TOGGLE_DRAWER_OPEN,
  HANDLE_DRAWER_OPEN,
  SET_TEAMMEMBER_INFO,
  SHOW_LOADING,
  HIDDEN_LOADING,
  SET_SNAKBAR_MODEL,
  TOGGLE_SNAKBAR,
  REMOVE_SNAKBAR,
  REMOVE_ALL_SNAKBARS,
  TOGGLE_PROFILE_DROPDOWN,
  CLOSE_PROFILE_DROPDOWN,
  TOGGLE_NOTIFICATION_DROPDOWN,
  CLOSE_NOTIFICATION_DROPDOWN,
  UPDATE_NOTIFICATION_LIST,
} from "./types";
import SnakbarModel from "shared-components/src/models/SnakbarModel";

export const mutations = {
  [SET_TEAMMEMBER_INFO](state: AppState, payload: { teamMemberInfo: Teammember }) {
    state.teamMemberInfo = payload.teamMemberInfo;
  },
  [SET_USER_INFO](state: AppState, payload: { userInfo: UserInfo }) {
    state.userInfo = payload.userInfo;
  },
  [OPEN_QUICK_LOGIN_DIALOG](state: AppState) {
    state.quickLoginDialog = true;
  },
  [CLOSE_QUICK_LOGIN_DIALOG](state: AppState) {
    state.quickLoginDialog = false;
  },
  [TOGGLE_DRAWER_OPEN](state: AppState) {
    state.drawerIsOpen = !state.drawerIsOpen;
  },
  [HANDLE_DRAWER_OPEN](state: AppState, value: boolean) {
    state.drawerIsOpen = value;
  },
  [SHOW_LOADING](state: AppState) {
    state.showLoading = true;
  },
  [HIDDEN_LOADING](state: AppState) {
    state.showLoading = false;
  },
  [SET_SNAKBAR_MODEL](state: AppState, value: SnakbarModel) {
    // generate a unique id
    value.id = (value.id || "") + new Date().getTime();
    if (!value.timeout) {
      value.timeout = 5000;
    }
    const repetitiveSnakbar = state.snakbars.find((s) => s.body.localeCompare(value.body) === 0);
    if (repetitiveSnakbar) {
      repetitiveSnakbar.show = false;
      setTimeout(() => {
        state.snakbars.splice(state.snakbars.indexOf(repetitiveSnakbar), 1);
        const snakbars = [value, ...state.snakbars];
        snakbars.splice(10);
        state.snakbars = [...snakbars];
      }, 200);
    } else {
      const snakbars = [value, ...state.snakbars];
      snakbars.splice(10);
      state.snakbars = [...snakbars];
    }
  },
  [TOGGLE_SNAKBAR](state: AppState, value: { show: boolean; id: string }) {
    const snakbar = state.snakbars.find((s) => s.id === value.id);
    if (snakbar) {
      snakbar.show = value.show;
    }
  },
  [REMOVE_SNAKBAR](state: AppState, id: string) {
    const snakbar = state.snakbars.find((s) => s.id === id);
    if (snakbar) {
      snakbar.show = false;
      setTimeout(() => {
        state.snakbars.splice(state.snakbars.indexOf(snakbar), 1);
      }, 200);
    }
  },
  [REMOVE_ALL_SNAKBARS](state: AppState) {
    state.snakbars = [];
  },
  [TOGGLE_PROFILE_DROPDOWN](state: AppState) {
    state.isProfileDropdownOpen = !state.isProfileDropdownOpen;
    if (state.isProfileDropdownOpen) {
      state.isNotificationOpen = false;
    }
  },
  [CLOSE_PROFILE_DROPDOWN](state: AppState) {
    state.isProfileDropdownOpen = false;
  },
  TOGGLE_NOTIFICATION_DROPDOWN(state: AppState) {
    state.isNotificationOpen = !state.isNotificationOpen;
  },
  [CLOSE_NOTIFICATION_DROPDOWN](state: AppState) {
    state.isNotificationOpen = false;
  },
  [UPDATE_NOTIFICATION_LIST](state: AppState) {
    state.updateNotificationListFlag = !state.updateNotificationListFlag;
  },
};
