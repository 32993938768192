<template>
  <div v-if="assessmentModel">
    <div v-if="assessmentInProgressOrNotStarted">
      <v-row>
        <v-col cols="12" class="text-center">
          <h2>Test Time: {{ formatDuration(assessmentModel.Questions.reduce((total, a) => total + a.Duration, 0)) }}</h2>
          <span v-if="assessmentModel.PassScore">Pass Score: {{ assessmentModel.PassScore }}</span>
        </v-col>
        <v-col cols="12" class="f-body-1">{{ assessmentModel.Title }} </v-col>
        <v-col cols="12" class="f-body-2">{{ assessmentModel.Description }} </v-col>
      </v-row>
      <div class="action-btns mt-3">
        <v-btn
          class="primary_btn"
          v-if="assessmentModel.SubmitTime == null && assessmentModel.EndTime == null"
          @click="startAssessment()"
          :disabled="isLoading"
          :loading="isLoading"
          >Start</v-btn
        >
        <div v-if="assessmentModel.SubmitTime != null || EndDateIsPassed()">
          <v-btn class="primary_btn mr-2" v-if="assessmentModel.IsRestartable" @click="RestartAssessment" :disabled="isLoading" :loading="isLoading"
            >Restart Assessment</v-btn
          >
          <v-btn class="primary_btn" @click="nextStep()" :disabled="isLoading" :loading="isLoading">{{
            courseId ? "Next Step" : "Return To RoadMap"
          }}</v-btn>
          <span class="completed-btn">
            completed
            <v-icon class="completed-btn">mdi-check</v-icon>
          </span>
        </div>
      </div>
    </div>
    <div v-if="!assessmentInProgressOrNotStarted && assessmentModel.Questions.length > 0">
      <v-row>
        <v-col cols="12">
          <h2>Remaining Time: {{ timerValue }}</h2>
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item v-for="(question, index) in assessmentModel.Questions" :key="index">
              <v-list-item-avatar>
                <v-icon v-if="question.Status != 'Submitted'">mdi-circle-outline</v-icon>
                <v-icon v-if="question.Status == 'Submitted'">mdi-circle</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title> {{ question.Title }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn color="grey-lighten-1" @click="startQuestion(question)" :disabled="isLoading" :loading="isLoading">
                  {{ question.Status != "Submitted" ? "Start" : "Edit" }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12">
          <v-btn color="grey-lighten-1" @click="SubmitAssessment" :disabled="isLoading" :loading="isLoading"> Submit Assessment </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="showEditor" fullscreen hide-overlay :scrollable="false" content-class="assessment-editor"
      ><AssessmentEditor
        :Question="selectedQuestion"
        :assessmentId="assessmentModel.Id"
        :courseId="courseId"
        :roadMapId="roadMapId"
        @Submitted="QuestionSubmitted"
        v-if="selectedQuestion"
      ></AssessmentEditor
    ></v-dialog>
  </div>
</template>

<script lang="ts">
import AssessmentModel, { Question } from "shared-components/src/models/AssessmentModel";
import AssessmentEditor from "./AssessmentEditor.vue";
import RoadMapService from "@/services/RoadMapService";
import moment from "moment";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    AssessmentEditor,
  },
  props: {
    assessmentModel: {
      type: Object as () => AssessmentModel,
      required: true,
    },
    courseId: {
      type: String,
    },
    roadMapId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.assessmentModel.EndTime) {
      this.runTimer();
    }
  },
  data() {
    return {
      showErrorAlert: false,
      isLoading: false,
      showEditor: false,
      selectedQuestion: null as Question | null,
      timerValue: null as string | null,
    };
  },
  methods: {
    EndDateIsPassed() {
      return this.assessmentModel.EndTime && moment.duration(moment.utc(this.assessmentModel.EndTime).diff(moment(moment.now()))).asMinutes() < 0;
    },
    async SubmitAssessment() {
      this.isLoading = true;
      this.assessmentModel.SubmitTime = await RoadMapService.SubmitRoadMapAssessment(this.assessmentModel.Id, this.roadMapId, this.courseId);
      this.$emit("AssessmentSubmited", this.assessmentModel.Id);
      this.$forceUpdate();
      this.isLoading = false;
    },
    async RestartAssessment() {
      this.isLoading = true;
      this.assessmentModel.EndTime = await RoadMapService.RestartRoadMapAssessment(this.assessmentModel.Id, this.roadMapId, this.courseId);
      if (this.assessmentModel.EndTime) {
        this.assessmentModel.SubmitTime = undefined;
      }
      this.runTimer();
      this.$forceUpdate();
      this.isLoading = false;
    },
    QuestionSubmitted(answer: string) {
      var index = this.assessmentModel.Questions.findIndex((item) => item.Id == this.selectedQuestion?.Id);
      if (index != -1) {
        this.assessmentModel.Questions[index].TalentAnswer = answer;
        this.assessmentModel.Questions[index].Status = "Submitted";
      }
      this.showEditor = false;
      this.selectedQuestion = null;
    },
    runTimer() {
      setInterval(() => {
        if (this.assessmentModel && this.assessmentModel.EndTime) {
          this.timerValue = this.formatDuration(
            Number.parseInt(
              moment
                .duration(moment.utc(this.assessmentModel.EndTime).diff(moment(moment.now())))
                .asMinutes()
                .toString()
            )
          );
        }
      }, 1000);
    },
    startQuestion(question: Question) {
      this.selectedQuestion = question;
      this.showEditor = true;
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${Number.parseInt(minutes.toString())} m` : `${minutes} m`;
    },
    async startAssessment() {
      this.isLoading = true;
      this.assessmentModel.EndTime = await RoadMapService.StartTeammemberRoadMapAssessment(this.assessmentModel.Id, this.courseId, this.roadMapId);
      this.runTimer();
      this.$forceUpdate();
      this.isLoading = false;
    },
    nextStep() {
      this.$emit("nextStep");
    },
  },
  computed: {
    assessmentInProgressOrNotStarted(): boolean {
      if (!this.assessmentModel.EndTime || this.EndDateIsPassed() || this.assessmentModel.SubmitTime) {
        return true;
      }
      return false;
    },
  },
  watch: {
    showEditor(newValue, old) {
      if (!newValue) {
        this.selectedQuestion = null;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.action-btns {
  justify-content: left;
  padding: 0;
}
</style>
