<template>
  <div class="courses_container">
    <v-overlay
      class="filter-overlay"
      v-model="overlay"
      scroll-strategy="block"
      location-strategy="connected"
      opacity="0.8"
      persistent
      @click="clickOnOverlay"
    >
    </v-overlay>
    <CourseFilterTM
      @showOverlay="filterToggle"
      @backBtnClicked="handelBackToRoadMap"
      @doSearch="handleSearchFilter"
      @time-chip-closed="handelTimeChipClosed"
      :ShowBackBtn="backButtonVisibility"
      :top="topValue"
      :height="heightValue"
      :padding="paddingValue"
      :overlay="overlay"
      :showFilter="showFilter"
      :skills="skills"
    >
    </CourseFilterTM>

    <div class="card-list">
      <div class="loading-container" v-if="isLoading">
        <v-progress-circular color="primary" indeterminate :size="80"> Loading</v-progress-circular>
      </div>
      <v-col cols="12" v-for="course in courses" :key="course.Id" v-if="!isLoading">
        <CourseListItems :course="course" :skills="skills"></CourseListItems>
      </v-col>
      <v-col cols="12" v-if="showEmptyCourseCard && !isLoading">
        <EmptyCourseCard></EmptyCourseCard>
      </v-col>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CourseListItems from "./CourseListItems.vue";
import CoreSkill from "shared-components/src/models/CoreSkill";
import CourseFilterTM from "./CourseFilterTM.vue";
import CourseModel from "shared-components/src/models/CourseModel";
import FindCoursesRequest from "shared-components/src/models/requests/FindCoursesRequest";
import CoreSkillService from "../../../services/CoreSkillService";
import CourseService from "../../../services/CourseService";
import EmptyCourseCard from "./EmptyCourseCard.vue";

export default defineComponent({
  components: {
    CourseListItems,
    CourseFilterTM,
    EmptyCourseCard,
  },
  async mounted() {
    try {
      this.isLoading = true;
      await this.loadSkillsList();
      var result = await CourseService.GetList();
      this.courses = result;
    } catch (error) {
      console.error("Error fetching data:", error);
      this.showErrorAlert = true;
    } finally {
      this.isLoading = false;
    }
  },
  props: {
    showAsPage: {
      type: Boolean,
      default: false,
    },
    backButtonVisibility: {
      type: Boolean,
      default: true,
    },
    topValue: {
      type: String,
      default: "1px",
    },
    heightValue: {
      type: String,
      default: "610px",
    },
    paddingValue: {
      type: String,
      default: "40px",
    },
  },
  data() {
    return {
      overlay: false,
      showFilter: false,
      selectedTime: null as any,
      skills: [] as CoreSkill[],
      courses: [] as CourseModel[],
      searchQuery: {} as FindCoursesRequest,
      showFilteredTime: false,
      showErrorAlert: false,
      showEmptyCourseCard: false,
      isLoading: false,
    };
  },
  methods: {
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
    clickOnOverlay() {
      this.showFilter = false;
      this.overlay = !this.overlay;
    },
    filterToggle(filterVisible: any) {
      this.overlay = !this.overlay;
      this.showFilter = filterVisible;
      this.$emit("showOverlay", filterVisible);
    },
    handelBackToRoadMap() {
      this.$emit("close-course");
    },
    handleSearchFilter(filterValues: { duration: any; level: any; skill: any; text: any }) {
      this.showFilteredTime = filterValues.duration !== null;
      this.searchQuery.Duration = filterValues.duration;
      this.searchQuery.Level = filterValues.level;
      this.searchQuery.Skill = filterValues.skill;
      this.searchQuery.Text = filterValues.text;
      this.searchQuery = { ...this.searchQuery };
    },
    handelTimeChipClosed() {
      this.selectedTime = null;
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.isLoading = true;
        this.showEmptyCourseCard = false;
        this.courses = [];
        CourseService.GetList(this.searchQuery).then((res: any) => {
          if (res.length <= 0) {
            this.showEmptyCourseCard = true;
          }
          this.courses = res;
          this.isLoading = false;
        });
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";

.courses_container {
  position: relative;

  .card-list {
    padding-top: 100px;
  }
}
</style>
