<template>
  <div class="main-container">
    <SignupForm form-id="signup-form" @submitting="onSubmitting($event)" />
    <div class="actions pt-0">
      <v-btn color="accent" class="pt-0" type="submit" :disabled="submitting" :loading="submitting" form="signup-form"> Register </v-btn>
    </div>
    <div class="login-link">
      <router-link to="/register/login">I have an account</router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SignupForm from "@/components/User/SignupForm.vue";
import firebase from "firebase/compat/app";

export default defineComponent({
  components: {
    SignupForm,
  },
  data() {
    return {
      submitting: false,
      valid: true,
      dialog: false,
      cvFile: null as File | null,
    };
  },
  methods: {
    onSubmitting(value: any) {
      this.submitting = value;
    },
    goToLogin() {
      this.$router.push("/register/login");
    },
    emailEntered(e: any) {
      (this.$refs.txtPassword as HTMLElement).focus();
    },
    passwordEntered(e: any) {
      (this.$refs.txtRePassword as HTMLElement).focus();
    },
    fileChanged(file: File) {
      this.cvFile = file;
    },
    googleAuth() {
      try {
        this.submitting = true;
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(provider);
      } catch (ex) {
      } finally {
        this.submitting = false;
      }
    },
  },
  mounted() {
    this.dialog = window.innerWidth <= 590;
  },
});
</script>
<style scoped lang="scss">
.main-container {
  width: 100%;
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 10px;
    .v-btn {
      flex-grow: 1;
      height: 45px !important;
    }
  }
  .login-link {
    width: 100%;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
  }
}
</style>
