<template>
  <v-card :loading="pageLoading" :disabled="pageLoading" class="custom-card">
    <v-card-text>
      <div class="reset-pass">
        <div v-if="(token && email) || !token">
          <v-form ref="resetForm" autocomplete="off">
            <v-row>
              <v-col cols="12">
                <TextField
                  v-model="email"
                  prepend-icon="$vuetify.icons.email"
                  label="Email"
                  placeholder="Email"
                  type="text"
                  class="login-field"
                  outlined
                  :rules="[validations.RequiredCheck, validations.EmailCheck]"
                  :readonly="token && token.length > 0 && email && email.length > 0"
                />
              </v-col>
              <v-col cols="12" v-if="token && token.length > 0 && email && email.length > 0">
                <PasswordField
                  prepend-icon="$vuetify.icons.key"
                  :rules="[validations.RequiredCheck]"
                  v-model="newPassword"
                  label="Enter New Password"
                  placeholder="Enter New Password"
                  outlined
                >
                </PasswordField>
              </v-col>
            </v-row>
          </v-form>
          <div class="actions">
            <v-btn :disabled="loading" v-if="!token" :loading="loading" color="accent" @click="resetPassword">Reset</v-btn>
            <v-btn :disabled="pageLoading" v-if="token && email" :loading="pageLoading" color="accent" @click="saveNewPassword">Save</v-btn>
          </div>
          <div class="login-link">
            <router-link to="/register/login">Back</router-link>
          </div>
        </div>
        <div v-if="token && !email && !pageLoading" class="text-center">There is an error on Validating Token</div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AppHelper from "shared-components/src/utils/AppHelper";
import UserService from "@/services/UserService";
import validations from "shared-components/src/utils/validations";
import { FormState } from "final-form";
import store from "@/store";

export default defineComponent({
  components: {},
  data() {
    return {
      submitting: false,
      resetPassSent: false,
      token: null as string | null,
      pageLoading: false,
      loading: false,
      validations,
      formState: null as FormState<any> | null,
      email: "",
      newPassword: null as string | null,
      showPassword: false,
      callbackUrl: `${window.location.origin}/register/reset-password`,
    };
  },
  mounted() {
    if (AppHelper.IsAzureEnv()) {
      this.token = this.$route.query.token as string;
      if (this.token) {
        this.pageLoading = true;
        UserService.validateResetToken(this.token)
          .then((result) => {
            this.email = result;
          })
          .catch((err) => {
            store.dispatch("showErrorMessage", err)
          })
          .finally(() => {
            this.pageLoading = false;
          });
      }
    }
  },
  methods: {
    async saveNewPassword() {
      const isValid = await (this.$refs.resetForm as any).validate();
      if (isValid.valid && this.newPassword && this.token) {
        this.pageLoading = true;
        UserService.saveNewPassword(this.newPassword, this.token)
          .then((result) => {
            this.$router.push("/login");
          })
          .catch((err) => {
            store.dispatch("showErrorMessage", err)
          })
          .finally(() => {
            this.pageLoading = true;
          });
      }
    },
    async resetPassword() {
      const isValid = await (this.$refs.resetForm as any).validate();
      if (isValid.valid) {
        this.loading = true;
        UserService.resetPassword(this.email, this.callbackUrl)
          .then((result) => {
            store.dispatch("showSuccessMessage", "Reset Password link has been sent to your email successfully.")
          })
          .catch((err) => {
            store.dispatch("showErrorMessage", err.message || err.toStrting())
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.custom-card {
  width: 100%;
  box-shadow: none !important;

  .v-card__text {
    padding: 0;
  }
}
.reset-pass {
  width: 100%;
  min-height: 276px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;

  .login-link {
    width: 100%;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
  }
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;
  .v-btn {
    flex-grow: 1;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
.v-btn {
  height: 45px !important;
}
.more-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
@media screen and (min-width: 800px) {
  .login-field {
    max-width: 600px;
  }
}
</style>
