<template>
  <div :class="classnames" :style="{ cursor, userSelect }" @mousedown="onMouseDown">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      isResizing: false,
    };
  },

  computed: {
    classnames() {
      return ["multipane", "layout-v", this.isResizing ? "is-resizing" : ""];
    },
    cursor() {
      return this.isResizing ? "row-resize" : "";
    },
    userSelect() {
      return this.isResizing ? "none" : "";
    },
  },

  methods: {
    onMouseDown({ target: resizer, pageX: initialPageX, pageY: initialPageY }) {
      if (resizer.className && resizer.className.match("multipane-resizer-v")) {
        let self = this;
        let { $el: container, layout } = self;

        let pane = resizer.previousElementSibling;
        let { offsetHeight: initialPaneHeight } = pane;
        const { addEventListener, removeEventListener } = window;

        const resize = (initialSize, offset = 0) => {
          let containerHeight = container.clientHeight;
          let paneHeight = initialSize + offset;
          return (pane.style.height = (paneHeight / containerHeight) * 100 + "%");
        };

        self.isResizing = true;
        let size = resize();

        const onMouseMove = function ({ pageX, pageY }) {
          size = resize(initialPaneHeight, pageY - initialPageY);
        };

        const onMouseUp = function () {
          size = resize(pane.clientHeight);
          self.isResizing = false;

          removeEventListener("mousemove", onMouseMove);
          removeEventListener("mouseup", onMouseUp);
        };

        addEventListener("mousemove", onMouseMove);
        addEventListener("mouseup", onMouseUp);
      }
    },
  },
});
</script>

<style lang="scss">
.multipane {
  display: flex;

  &.layout-v {
    flex-direction: column;

    .multipane-resizer-v {
      display: block;
      position: relative;
      z-index: 2;
      width: 100%;
      height: 10px;
      margin-top: -10px;
      top: 5px;
      cursor: row-resize;
    }
  }
}
</style>
