<template>
  <div>
    <loading-component></loading-component>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import TeamMemberService from "@/services/TeamMemberService";
import UserInfo from "shared-components/src/models/UserInfo";
import UserService from "@/services/UserService";
import { Teammember } from "shared-components/src/models/Teammember";
import { UPDATE_NOTIFICATION_LIST } from "@/store/types";
import { mapActions } from "vuex";
import { messaging, firebaseVapidKey } from "shared-components/src/plugins/firebase";
import store from "@/store";

export default defineComponent({
  components: {
    LoadingComponent,
  },
  mounted() {
    let queryData = { idToken: this.$route.query.idToken, refreshToken: this.$route.query.refreshToken };
    this.logginIn(queryData);
  },
  methods: {
    ...mapActions([UPDATE_NOTIFICATION_LIST]),
    showError(error: string) {
      store.dispatch("showErrorMessage", error)
    },
    onLoginSuccess() {
      const returnUrl = this.$route.query.returnUrl as string;
      this.$router
        .push(returnUrl || "/team-member/dashboard")
        .then((res) => {
          return res;
        })
        .catch(() => {
          /**/
        });
    },
    onLoginFailure() {
      this.$router
        .push("/register/login")
        .then((res) => {
          return res;
        })
        .catch(() => {
          /**/
        });
    },
    setUserInfo(userInfo: UserInfo) {
      return store.dispatch("setUserInfo", { userInfo, vm: this });
    },
    setTeamMemberInfo(teamMemberInfo: Teammember) {
      return store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
    },
    setPushToken() {
      if (messaging) {
        messaging
          .getToken({ vapidKey: firebaseVapidKey })
          .then((currentToken: any) => {
            if (currentToken) {
              UserService.sendPushToken(currentToken);
              localStorage.firebaseToken = currentToken;
            } else {
              // Show permission request UI
              console.log("No registration token available. Request permission to generate one.");
              // ...
            }
          })
          .catch((err: any) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });
        messaging.onMessage((payload: any) => {
          console.log("Message received. ", payload);
          this.UPDATE_NOTIFICATION_LIST();
        });
      }
    },
    async logginIn(data: any) {
      try {
        const userInfo = await UserService.loginToken(data);
        if (userInfo.id) {
          this.setUserInfo(userInfo);
          this.setPushToken();
          try {
            const teamMemberInfo = await TeamMemberService.getTeammemberByEmail(userInfo.email || "");
            if (teamMemberInfo) {
              this.setTeamMemberInfo(teamMemberInfo);
            }

            this.onLoginSuccess();
          } catch (err: any) {
            store.dispatch("showErrorMessage", err.message || err.toStrting())
            this.onLoginFailure();
          }
        } else {
          store.dispatch("showErrorMessage", "Wrong Email or Password")
          this.onLoginFailure();
        }
      } catch (error: any) {
        store.dispatch("showErrorMessage", error.message || error.toStrting())
        this.onLoginFailure();
      }
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
  },
});
</script>
