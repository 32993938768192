import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "emptyResult d-flex justify-center mb-6"
}
const _hoisted_2 = { class: "pa-md-16 ma-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LastTwelveMonth = _resolveComponent("LastTwelveMonth")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_TimesheetChartReport = _resolveComponent("TimesheetChartReport")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, { disabled: _ctx.loading }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_LastTwelveMonth, {
              onFilterDataByDate: _ctx.filterDataByDate,
              selectLastMonth: _ctx.selectLastMonth
            }, null, 8, ["onFilterDataByDate", "selectLastMonth"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_v_card, { loading: _ctx.loading }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12",
                  sm: "12",
                  xs: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TimesheetChartReport, {
                      activities: _ctx.activityReport,
                      technologies: _ctx.technologyReport,
                      epics: _ctx.epicReport
                    }, null, 8, ["activities", "technologies", "epics"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            (
            !_ctx.loading &&
            (!_ctx.activityReport || _ctx.activityReport.length === 0) &&
            (!_ctx.technologyReport || _ctx.technologyReport.length === 0) &&
            (!_ctx.epicReport || _ctx.epicReport.length === 0)
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.message), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}