import axios, { AxiosRequestConfig, AxiosRequestTransformer, AxiosResponse } from "axios";
import { baseApiUrl, baseAzureApiUrl } from "shared-components/src/plugins/firebase";
import Auth from "shared-components/src/services/Auth";
import { Profile } from "shared-components/src/definitions/config";
import AppHelper from "shared-components/src/utils/AppHelper";
import UserService from "./UserService";
import moment from "moment";

if (AppHelper.IsAzureEnv()) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response ? error.response.status : null;
      if (status === 401 && localStorage.refreshToken && localStorage.refreshToken != "null") {
        return ApiService.refreshToken().then(() => {
          error.config.headers["Authorization"] = localStorage.idToken;
          error.config.headers["RefreshToken"] = localStorage.refreshToken;
          return axios.request(error.config);
        });
      }
      return Promise.reject(error);
    }
  );
}
class Cache {
  public static RefreshingCall: any = null;
}

const dateTransformer = (data: any): any => {
  if (data instanceof Date) {
    return moment(data).format("YYYY-MM-DDTHH:mm:ss");
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, dateTransformer(value)]));
  }
  return data;
};

const v1Prefix = "v1";

const ApiService = {
  async refreshToken() {
    if (Cache.RefreshingCall) {
      return Cache.RefreshingCall;
    }
    const config = await this.getAzureConfig();
    const refreshingCall = axios
      .get(`${v1Prefix}/authentication/refreshToken`, config)
      .then(({ data: { id_token, refresh_token } }) => {
        localStorage.idToken = id_token;
        localStorage.refreshToken = refresh_token;
        Cache.RefreshingCall = null;
        return Promise.resolve(true);
      })
      .catch((error) => {
        Cache.RefreshingCall = null;
        UserService.logout(null);
        window.location.reload();
      });
    Cache.RefreshingCall = refreshingCall;
    return refreshingCall;
  },

  getConfig(): Promise<AxiosRequestConfig> {
    const user = Auth.getCurrentFirebaseUser();
    return new Promise((resolve, reject) => {
      if (user) {
        user
          .getIdToken()
          .then((tkn: any) => {
            resolve({ baseURL: baseApiUrl, headers: { Authorization: tkn } } as AxiosRequestConfig);
          })
          .catch((error: any) => {
            resolve({ baseURL: baseApiUrl } as AxiosRequestConfig);
          });
      } else {
        resolve({ baseURL: baseApiUrl } as AxiosRequestConfig);
      }
    });
  },
  getAzureConfig(hasFile = false, transformDate: boolean = true): Promise<AxiosRequestConfig> {
    return new Promise((resolve, reject) => {
      let token = "";
      let refreshToken = "";
      if (localStorage.idToken) {
        token = localStorage.idToken;
        refreshToken = localStorage.refreshToken || "";
      }
      const configModel = {
        baseURL: baseAzureApiUrl,
        headers: { Authorization: token, RefreshToken: refreshToken, ContentType: Profile.RequestTypes.JSON },
      } as AxiosRequestConfig;
      if (hasFile && configModel.headers) {
        configModel.headers.ContentType = Profile.RequestTypes.MULTIPART;
      }
      if (transformDate) {
        configModel.transformRequest = [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])];
      }
      resolve(configModel);
    });
  },

  query(resource: any, params: any): Promise<AxiosResponse<any>> {
    return new Promise((resolve, reject) => {
      axios
        .get(resource, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async get(resource: any, slug = "", dateTransform: boolean = true): Promise<AxiosResponse<any>> {
    const config = await this.getAzureConfig(false, dateTransform);
    let url = `${v1Prefix}${resource}`;
    if (slug) {
      url += `/${slug}`;
    }
    return axios.get(`${url}`, config).catch((error) => {
      throw new Error(`ApiService : ${error}`);
    });
  },

  async post(resource: any, params: any, dateTransform: boolean = true): Promise<AxiosResponse<any>> {
    const config = await this.getAzureConfig(false, dateTransform);
    return axios.post(`${v1Prefix}${resource}`, params, config);
  },

  async update(resource: any, slug: any, params: any) {
    let url = `${v1Prefix}${resource}`;
    if (slug) {
      url += `/${slug}`;
    }
    return axios.put(`${url}`, params, await this.getConfig());
  },

  async put(resource: any, params: any, dateTransform: boolean = true): Promise<AxiosResponse<any>> {
    const config = await this.getAzureConfig(false, dateTransform);
    return axios.put(`${v1Prefix}${resource}`, params, config);
  },

  async upload(resource: any, file: File, dateTransform: boolean = true): Promise<AxiosResponse<any>> {
    const config = await this.getAzureConfig(true, dateTransform);
    var formData = new FormData();
    formData.append("file", file as File);
    return axios.post(`${v1Prefix}${resource}`, formData, config);
  },

  async delete(resource: any, dateTransform: boolean = true): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      const config = await this.getAzureConfig(false, dateTransform);
      axios
        .delete(`${v1Prefix}${resource}`, config)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default ApiService;
