import Epic from "shared-components/src/models/Epic";
import ApiService from "./ApiService";

export default class EpicService {
  public static async getEpicOfProject(projectId: string): Promise<Epic | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/epic/getEpicOfProject/${projectId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve(null);
          } else {
            resolve({ id: result.data.id, ...result.data } as Epic);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async addEpic(epicTitle: string, projectId: string): Promise<Epic | null> {
    return new Promise((resolve, reject) => {
      ApiService.post("/epic/save", { epicTitle, projectId })
        .then((result) => {
          if (result.data) {
            resolve(result.data as Epic);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
