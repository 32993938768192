import { createStore } from "vuex";
import { state } from "./state";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import createPersistedState from "vuex-persistedstate";
import { Teammember } from "shared-components/src/models/Teammember";
import UserInfo from "shared-components/src/models/UserInfo";
import SnakbarModel from "shared-components/src/models/SnakbarModel";

export interface AppState {
  quickLoginDialog: boolean;
  isProfileDropdownOpen: boolean;
  isNotificationOpen: boolean;
  userInfo: UserInfo;
  drawerIsOpen: boolean;
  teamMemberInfo: Teammember | null;
  showLoading: boolean;
  idToken: string;
  hasFirebaseAuth: boolean;
  snakbars: SnakbarModel[];
  updateNotificationListFlag: boolean;
}

export default createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [createPersistedState()],
});
