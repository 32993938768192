<template>
  <div>
    <v-card>
      <v-card-title> Pay Run List </v-card-title>
      <v-card-text>
        <v-data-table :items="items" :headers="headers" :loading="loading">
          <template v-slot:item.Duration="{ item }">
            <strong>{{ getDuration(item) }}</strong>
          </template>
          <template v-slot:item.TotalHours="{ item }">
            <span>{{ getTotalHours(item) }}</span>
          </template>
          <template v-slot:item.AUDTotalPay="{ item }">
            <span>${{ formatNumber(item.AUDTotalPay) }}</span>
          </template>
          <template v-slot:item.LCTotalPay="{ item }">
            <span>{{ formatNumber(item.LCTotalPay) }} {{ item.ExchangeCurrency ? item.ExchangeCurrency : "TMN" }}</span>
          </template>
          <template v-slot:item.TMApproved="{ item }">
            <span class="success--text" v-if="item.TMApproved">Approved</span>
            <span v-else class="red--text">Waiting</span>
          </template>
          <template v-slot:item.Actions="{ item }">
            <div>
              <v-btn class="mt-1" icon fab x-small title="show more inforamtion!" @click="showPayrun(item)"><v-icon>mdi-magnify</v-icon></v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-if="showDetailPopUp" :key="`PDF-Modal-${pdfVersion}`" class="scrollable-dialog" max-width="800" v-model="showDetailPopUp" persistent @keydown.esc="closePayrun">
      <PayRunDetail
        :key="`PDF-${pdfVersion}`"
        :model="selectedPayRun"
        :payrunAuditTrail="payrunAuditTrail"
        @close="closePayrun"
        @approveByTM="approve"
      ></PayRunDetail>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import PayRun from "shared-components/src/models/PayRun";
import { Teammember } from "shared-components/src/models/Teammember";
import Utils from "shared-components/src/utils/Utils";
import PayRunDetail from "shared-components/src/components/PayRan/PayRunDetail.vue";
import { AuditTrailEvents, AuditTrailRecordTypes } from "shared-components/src/definitions/constants";
import AuditTrail, { RecordType, PayRunAuditTrail } from "shared-components/src/models/AuditTrail";
import AuditTrailService from "shared-components/src/services/AuditTrailService";
import PayRunService from "@/services/PayRunService";
import store from "@/store";

export default defineComponent({
  components: {
    PayRunDetail,
  },
  data() {
    return {
      pdfVersion: 0,
      selectedPayRun: null as PayRun | null,
      showDetailPopUp: false,
      auditTrails: [] as AuditTrail[],
      payrunAuditTrail: {} as PayRunAuditTrail,
      headers: [
        { title: "Duration", sortable: false, align: "start", value: "Duration" },
        { title: "TotalHours", sortable: false, value: "TotalHours" },
        { title: "TotalPayment AUD", value: "AUDTotalPay", sortable: false },
        { title: "TotalPayment Exchanged", value: "LCTotalPay", sortable: false },
        { title: "Confirmed by You", sortable: false, value: "TMApproved" },
        { title: "Actions", sortable: false, value: "Actions" },
      ],
      items: [] as PayRun[],
      loading: true,
      teamMemberInfo: store.state.teamMemberInfo as Teammember,
    };
  },
  methods: {
    resetPayRunAuditTrail(auditModel: any) {
      var newModel = {
        AdminApprovedDate: auditModel.AdminApprovedDate,
        TMApprovedDate: auditModel.TMApprovedDate,
        PaymentRefAddedDate: auditModel.PaymentRefAddedDate,
      };
      this.payrunAuditTrail = newModel;
    },
    async approve(payRunModel: any) {
      await PayRunService.approveByTM(payRunModel.id);
      var findPayrun = this.items.find((c) => c.id === payRunModel.id);
      if (findPayrun) {
        findPayrun.TMApproved = true;
      }
      var auditTrail = {
        DateTime: new Date(),
        RecordType: { Type: AuditTrailRecordTypes.Payrun, Id: payRunModel.id } as RecordType,
        UserId: store.state.userInfo.id,
        Event: AuditTrailEvents.TMApprove,
      } as AuditTrail;
      AuditTrailService.set(auditTrail);
      if (this.payrunAuditTrail && auditTrail.DateTime) {
        this.payrunAuditTrail.TMApprovedDate = Utils.toVsDateFormat(auditTrail.DateTime);
        this.resetPayRunAuditTrail(this.payrunAuditTrail);
      }

      this.closePayrun();
      this.loadingItems();
    },
    async showPayrun(item: PayRun) {
      this.selectedPayRun = item;
      this.showDetailPopUp = true;
      this.auditTrails = await AuditTrailService.getAuditTrailListByType(item.id);
      if (this.auditTrails) {
        var tmApproved = this.auditTrails.find((item) => item.Event === AuditTrailEvents.TMApprove);
        var adminApproved = this.auditTrails.find((item) => item.Event === AuditTrailEvents.AdminApprove);
        var paymentRefAdded = this.auditTrails.find((item) => item.Event === AuditTrailEvents.PaymentRefAdd);
        var payrunAuditModel = {} as PayRunAuditTrail;
        if (tmApproved && tmApproved.DateTime) {
          payrunAuditModel.TMApprovedDate = Utils.toVsDateFormat(tmApproved.DateTime);
        }
        if (adminApproved && adminApproved.DateTime) {
          payrunAuditModel.AdminApprovedDate = Utils.toVsDateFormat(adminApproved.DateTime);
        }
        if (paymentRefAdded && paymentRefAdded.DateTime) {
          payrunAuditModel.PaymentRefAddedDate = Utils.toVsDateFormat(paymentRefAdded.DateTime);
        }
        this.resetPayRunAuditTrail(payrunAuditModel);
      }
    },
    closePayrun() {
      this.showDetailPopUp = false;
      this.pdfVersion++;
    },
    loadingItems(): Promise<PayRun[]> {
      return PayRunService.getPayRuns();
    },
    getDuration(item: PayRun): string {
      return `${Utils.toVsDateFormat(item.FromDate)} ~ ${Utils.toVsDateFormat(item.ToDate)}`;
    },
    getTotalHours(item: PayRun): number {
      let retVal = 0;
      item.Items.forEach((i) => {
        retVal += i.TotalHours;
      });
      return retVal;
    },
    formatNumber(val: number): string {
      return Utils.moneyFormat(val);
    },
    errorRaised(msg: string): void {
      store.dispatch("showErrorMessage", msg)
    },
  },
  mounted() {
    store.dispatch("showLoading");
    this.loadingItems()
      .then((payRuns) => {
        const anyPayRuns = payRuns as any;
        this.items = anyPayRuns.sort((itemA: any, itemB: any) => itemB.ToDate - itemA.ToDate);
      })
      .catch((error) => {
        this.errorRaised("There is an error in fetching PayRuns from store!");
      })
      .finally(() => {
        this.loading = false;
        store.dispatch("hiddenLoading");
      });
  },
});
</script>
