import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header--title" }
const _hoisted_3 = { class: "user-name" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationDropDown = _resolveComponent("NotificationDropDown")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _directive_closeOnClick = _resolveDirective("closeOnClick")!

  return (_ctx.teamMemberInfo && _ctx.teamMemberInfo.Email)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["profile-dropdown", { 'is-open elevation-10': _ctx.profileDropdown }])
      }, [
        _createVNode(_component_v_card_text, {
          class: "pa-0 w-100",
          justify: "end",
          align: "right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_NotificationDropDown, {
                  updateNotificationListFlag: _ctx.updateNotificationListFlag,
                  userId: _ctx.teamMemberInfo && _ctx.teamMemberInfo.UserAccountsId,
                  onErrorRaise: _ctx.errorRaise,
                  onSuccessRaise: _ctx.successRaise,
                  class: "notification-drp"
                }, null, 8, ["updateNotificationListFlag", "userId", "onErrorRaise", "onSuccessRaise"])
              ]),
              _: 1
            }),
            _withDirectives((_openBlock(), _createBlock(_component_v_col, {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.TOGGLE_PROFILE_DROPDOWN()), ["prevent"])),
              class: "heads pa-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _withDirectives(_createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_v_tooltip, { left: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), _toDisplayString(_ctx.teamMemberInfo.FirstName + " " + _ctx.teamMemberInfo.LastName), 17)
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.teamMemberInfo.FirstName + " " + _ctx.teamMemberInfo.LastName), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ], 512), [
                    [_vShow, !_ctx.hideName]
                  ]),
                  (_ctx.teamMemberInfo.PhotoUrl)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_v_img, {
                          src: _ctx.teamMemberInfo.PhotoUrl,
                          alt: "profile-photo",
                          class: "profile-photo"
                        }, null, 8, ["src"])
                      ]))
                    : (_openBlock(), _createBlock(_component_v_icon, {
                        key: 1,
                        color: _ctx.profileDropdown ? 'primary' : 'secondary',
                        class: "profile-photo"
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("mdi-account-circle")
                        ])),
                        _: 1
                      }, 8, ["color"]))
                ]),
                (_ctx.profileDropdown)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_ctx.profileDropdown)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: "/team-member/profile",
                            class: "menu-item email"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.teamMemberInfo.Email), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_list, { class: "pt-0 main-menu-items" }, {
                        default: _withCtx(() => [
                          (_ctx.commitments && _ctx.commitments.length > 0)
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 0,
                                to: "/team-member/timesheet",
                                class: "menu-item",
                                title: "Timesheet"
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_list_item, {
                            to: "/register/logout",
                            class: "menu-item",
                            title: "Log Out"
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })), [
              [_directive_closeOnClick]
            ])
          ]),
          _: 1
        })
      ], 2))
    : _createCommentVNode("", true)
}