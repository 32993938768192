<template>
  <notification-list />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import NotificationList from "shared-components/src/components/Notification/NotificationList.vue";

export default defineComponent({
  components: {
    NotificationList,
  },
});
</script>
