import Leave from "shared-components/src/models/Leave";
import Utils from "shared-components/src/utils/Utils";
import ApiService from "./ApiService";

export default class LeaveService {
  public static async set(model: Leave): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post("/leave/save", model)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  }

  public static getListInDateRange(startDate: Date, endDate: Date): Promise<Leave[]> {
    return new Promise((resolve, reject) => {
      const fromDate = Utils.addTimezoneOffsetToStartDate(startDate);
      const toDate = Utils.addTimezoneOffsetToEndDate(endDate);
      ApiService.post("/leave/GetDateRangeList", { startDate: fromDate, endDate: toDate })
        .then((leaveResult) => {
          if (!leaveResult.data) {
            resolve([]);
          } else {
            const items = leaveResult.data.map((doc: any) => {
              const leaveData = doc;
              leaveData.Date = new Date(leaveData.Date);
              const retVal = { id: doc.id, ...leaveData } as Leave;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((error) => reject(error));
    });
  }
  public static delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/leave/remove/${id}`)
        .then((result) => {
          resolve();
        })
        .catch((error) => reject(error));
    });
  }
}
