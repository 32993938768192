<template>
  <v-row>
    <section class="avatars-group pa-3 pt-0 stacked">
      <div
        :class="avatar.isClicked ? 'selectedAvatar ' + touchClass + '' : touchClass"
        class="avatars-group__item"
        v-for="avatar in avatars"
        :key="`avatar-id-${avatar.id}`"
      >
        <v-tooltip top>
          <template v-slot:activator="{ props }">
            <v-avatar class="selectedAvatarIcon" v-if="avatar.id" v-bind="props" :color="!avatar.photoUrl ? getColour(avatar.fullName) : null">
              <slot></slot>
              <img class="pa-1 profile-photo" v-if="avatar.photoUrl" :src="avatar.photoUrl" :alt="avatar.fullName" />
              <span v-else class="text-white">
                {{ getInitials(avatar.fullName) }}
              </span>
            </v-avatar>
          </template>
          <b>{{ avatar.lable }}</b>
          <br />
          <span>{{ avatar.fullName }}</span>
        </v-tooltip>
      </div>

      <div v-if="showMore && hasMore" class="avatars-group__item">
        <v-avatar>
          <v-menu v-model="menu" :close-on-content-click="false" location="end">
            <template v-slot:activator="{ props }">
              <v-btn color="indigo" v-bind="props" icon="mdi-dots-horizontal"> </v-btn>
            </template>

            <v-card class="avatarMenu">
              <v-list>
                <v-list-item v-for="avatar in avatars" :key="`avatar-menu-id-${avatar.id}`">
                  <v-row :class="avatar.isClicked ? 'selectedAvatarListItem' : ''" class="avatar-moreList">
                    <v-col md="2">
                      <v-list-item-avatar>
                        <v-avatar :color="!avatar.photoUrl ? getColour(avatar.fullName) : null">
                          <slot></slot>
                          <img class="pa-1 profile-photo" v-if="avatar.photoUrl" :src="avatar.photoUrl" :alt="avatar.fullName" />
                          <span v-else class="text_white">
                            {{ getInitials(avatar.fullName) }}
                          </span>
                        </v-avatar>
                      </v-list-item-avatar>
                    </v-col>
                    <v-col class="moreList ml-0">
                      <v-list-item-content>
                        <v-list-item-title class="pl-2">{{ avatar.fullName }}</v-list-item-title>
                      </v-list-item-content>
                    </v-col>
                    <v-divider></v-divider>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-avatar>
      </div>
    </section>
  </v-row>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { DashboardTeammember } from "shared-components/src/models/Dahboard";
import AppHelper from "shared-components/src/utils/AppHelper";
import { Profile } from "shared-components/src/definitions/config";
import Avatar from "shared-components/src/models/Avatar";

export interface UiTeammember extends DashboardTeammember {
  isClicked: boolean | false;
}
export default defineComponent({
  props: {
    items: {
      type: Array as () => Avatar[],
      required: true,
    },
    hasAll: {
      type: Boolean,
      required: false,
    },
    hasMore: {
      type: Boolean,
      required: false,
    },
    doSort: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      showMore: false,
      hints: true,
      stackedLimit: Profile.Members.ClinetProfileLimit,
      menuMaxHeight: `${60 * 5 + 4}px`,
      stackedMenu: false,
      avatars: [] as any[],
      touchClass: "",
    };
  },
  mounted() {
    this.touchClass = this.isTouchScreen === 0 ? "touchAvatar" : "";
    if (this.hasMore) {
      this.avatars = this.avatarsSorted && this.avatarsSorted.length > 0 ? this.avatarsSorted.slice(0, this.stackedLimit) : [];
    } else {
      this.avatars = this.avatarsSorted;
    }
    if (this.hasAll) {
      this.avatars.splice(0, 0, { id: "", fullName: "All" } as UiTeammember);
    }
    if (this.avatars.length <= this.stackedLimit) {
      this.showMore = false;
    } else this.showMore = true;
  },
  methods: {
    getColour(name: string) {
      return AppHelper.GetColour(name);
    },
    getInitials(name: string) {
      return AppHelper.GetInitials(name);
    },
  },
  computed: {
    isTouchScreen() {
      var supportsTouch = "ontouchstart" in window || navigator.maxTouchPoints;
      return supportsTouch;
    },
    avatarsSorted() {
      if (this.items && this.items.length > 0) {
        if (this.doSort) {
          return this.items.sort((a: any, b: any) => a.fullName.localeCompare(b.fullName));
        } else {
          return this.items;
        }
      }
      return [];
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.avatarMenu {
  min-width: 300px;
  max-width: 305px;
  text-align: center;
}

.avatars-group.stacked {
  display: flex;
  flex-direction: row;
  direction: ltr;
  max-width: 100%;
  white-space: nowrap;
}
.avatars-group.stacked > * {
  margin-right: -8px;
}

.avatars-group__item {
  transition: all 0.1s ease-out;
  padding-top: 15px;
}

.avatars-group__item.more:hover {
  transform: none;
}
.selectedAvatar {
  padding-top: 0px;
}
.selectedAvatarListItem {
  color: $racecar;
}
.selectedAvatarIcon {
  box-shadow: 0px 0px 10px 3px $racecar;
}

.touchAvatar:hover {
  padding-top: 0px;
  z-index: 1;
}
.avatars-group .v-avatar,
.avatar-moreList .v-avatar {
  width: 45px;
  height: 45px;
  cursor: pointer;
  border: 4px solid;
}

.v-avatar.active,
.avatar-moreList .v-avatar.active {
  border-color: $success;
}
.v-avatar.upcomming,
.avatar-moreList .v-avatar.upcomming {
  border-color: $warning;
}
.v-avatar.expire,
.avatar-moreList .v-avatar.expire {
  border-color: $error;
}

.avatars-group .v-avatar span {
  font-size: 110%;
  font-weight: 700;
  letter-spacing: 0.1rem;
}
.avatar-moreList {
  cursor: pointer;
}
.moreList {
  display: flex;
  align-items: center;
}
.profile-photo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 0.2s;
}
</style>
