<template>
  <div class="editor-container">
    <div class="editor-header">{{ mode.toUpperCase() }}</div>
    <div class="editor-content">
      <textarea ref="editor" v-model="editorContent"></textarea>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/dracula.css";
// modes
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import "codemirror/mode/php/php.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/perl/perl.js";
import "codemirror/mode/ruby/ruby.js";
import "codemirror/mode/asterisk/asterisk.js";
import "codemirror/mode/sass/sass.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/mode/swift/swift.js";
import "codemirror/mode/vb/vb.js";
import "codemirror/mode/vue/vue.js";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/clike/clike.js";
// addons
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/closetag.js";
import "codemirror/addon/comment/comment.js";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/brace-fold.js";
import "codemirror/addon/fold/indent-fold.js";
import "codemirror/addon/fold/comment-fold.js";
import "codemirror/addon/hint/html-hint.js";
import "codemirror/addon/hint/css-hint.js";
import "codemirror/addon/hint/javascript-hint.js";
import "codemirror/addon/hint/xml-hint.js";
import "codemirror/addon/hint/sql-hint.js";
import "codemirror/addon/hint/javascript-hint.js";
import "codemirror/addon/hint/show-hint.js";

export default defineComponent({
  props: {
    mode: {
      type: String,
    },
    value: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      editorContent: "",
    };
  },
  mounted() {
    this.editorContent = this.value;
    const addonOptions = {
      autoCloseBrackets: true,
      autoCloseTags: true,
      foldGutter: true,
      showHint: true,
      gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
      extraKeys: {
        Tab: "emmetExpandAbbreviation",
        Esc: "emmetResetAbbreviation",
        Enter: "emmetInsertLineBreak",
      },
    };
    const editor = CodeMirror.fromTextArea((this.$refs.editor as any).$el, {
      mode: this.mode,
      theme: "dracula",
      readOnly: false,
      tabSize: 5,
      lineWrapping: true,
      lineNumbers: true,
      ...addonOptions,
    });
    if (this.value) {
      editor.setValue(this.value);
    }
    editor.on("inputRead", function (instance: any) {
      if (instance.state.completionActive) {
        return;
      }
      var cur = instance.getCursor();
      var token = instance.getTokenAt(cur);
      if (token.type && token.type != "comment") {
        CodeMirror.commands.autocomplete(instance);
      }
    });
    editor.on("change", (cm: any) => {
      const content = cm.getValue();
      if (this.$emit) {
        this.$emit("input", content);
      }
    });
    setTimeout(function () {
      editor.refresh();
    }, 100);
  },
  methods: {},
});
</script>
<style scoped lang="scss">
.editor-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .editor-header {
    height: 30px;
    color: #000;
  }
  .editor-content {
    height: 100%;
    width: 100%;
  }
}
</style>
