<template>
  <div class=""></div>
  <!-- <transition name="slide-y-transition" mode="out-in">
    <loading-component v-if="loggingOut"></loading-component>
  </transition> -->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserService from "@/services/UserService";
import store from "@/store";
import UserInfo from "shared-components/src/models/UserInfo";
import { Teammember } from "shared-components/src/models/Teammember";
import { defaultUser } from "@/store/state";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import { mapMutations } from "vuex";
import { SHOW_LOADING } from "@/store/types";

export default defineComponent({
  components: {
    LoadingComponent,
  },
  data() {
    return {
      loggingOut: false,
    };
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapMutations([SHOW_LOADING]),
    setUserInfo(userInfo: UserInfo) {
      return store.dispatch("setUserInfo", { userInfo, vm: this });
    },
    setTeamMemberInfo(teamMemberInfo: Teammember) {
      return store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
    },
    async logout() {
      try {
        var token = localStorage.firebaseToken;
        await UserService.logout(token);
        store.state.hasFirebaseAuth = false;
        localStorage.idToken = "";
        this.loggingOut = true;
        localStorage.firebaseToken = "";
        this.setUserInfo({ ...defaultUser });
        this.setTeamMemberInfo({} as Teammember);
        this.loggingOut = false;
        await this.$router.push("/register/login");
      } catch (error) {
        this.loggingOut = false;
        store.dispatch("showErrorMessage", error)
      }
    },
  },
});
</script>
