const Roles = Object.freeze(
{ 
  Admin: "Admin",
  Customer: "Customer",
  Linemanager: "Linemanager",
  Teammember: "Teammember",
  ProjectLead: "ProjectLead",
  PortfolioManager: "PortfolioManager",
}
);
export default Roles;