<template>
  <div :class="{ 'multipane-resizer-v': layout == 'v', 'multipane-resizer-h': layout == 'h' }"><slot></slot></div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    layout: {
      type: String,
    },
  },
});
</script>
