import Author from "shared-components/src/models/Author";
import ApiService from "./ApiService";

export default class AuthorService {
  public static async getList(linemanagerId: any, showIgnoredItems: boolean): Promise<Author[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/authors/${linemanagerId}?showIgnoredItems=${showIgnoredItems}`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              const retVal = { Author: item.Author, TeamMemberId: item.TeamMemberId, TeamMemberName: item.TeamMemberName } as Author;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
  public static async update(model: Author, linemanagerId: any): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.put(`/authors/${linemanagerId}`, model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async ignore(authorId: any, lineManagerId: any): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.put(`/authors/${authorId}/${lineManagerId}/ignore`, null)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
