<template>
  <WeeklyTimesheetAudit :isAdmin="false"></WeeklyTimesheetAudit>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WeeklyTimesheetAudit from "shared-components/src/components/weeklyTimesheetAudit/weeklyTimesheetAudit.vue";

export default defineComponent({
  components: {
    WeeklyTimesheetAudit,
  },
  data() {
  return {};
  },
  async mounted() {},
});
</script>
