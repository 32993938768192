import ApiService from "./ApiService";
import AssessmentModel from "shared-components/src/models/AssessmentModel";
import FindAssessmentsRequest from "shared-components/src/models/requests/FindAssessmentsRequest";

export default class AssessmentService {
  public static async GetList(criteria: FindAssessmentsRequest | null = null): Promise<AssessmentModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/tmAssessment/find`, criteria ? criteria : ({} as FindAssessmentsRequest))
        .then((result) => {
          if (!result.data) {
            resolve([] as AssessmentModel[]);
          } else {
            var response = result.data as AssessmentModel[];
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async GetById(assessmentId: string): Promise<AssessmentModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/tmAssessment/${assessmentId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as AssessmentModel);
          } else {
            var response = result.data as AssessmentModel;
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  }
}
