import PayRun, { Adjustment } from "shared-components/src/models/PayRun";
import Utils from "shared-components/src/utils/Utils";
import ApiService from "./ApiService";

export default class PayRunService {
  public static getPayRuns(): Promise<PayRun[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/payrun`, "")
        .then((result) => {
          if (!result.data) {
            resolve([] as PayRun[]);
          } else {
            const retVal = result.data.map((doc: any) => {
              const item = {
                id: doc.id,
                ...doc,
              } as PayRun;
              if (!item.Adjustments) {
                item.Adjustments = [] as Adjustment[];
              }

              if (!(item.FromDate instanceof Date)) {
                item.FromDate = this.stringToDate(item.FromDate);
              }
              if (!(item.ToDate instanceof Date)) {
                item.ToDate = this.stringToDate(item.ToDate);
              }
              item.Items.forEach((payrunItems) => {
                payrunItems.Weeks.forEach((week) => {
                  week.FromDate = this.stringToDate(week.FromDate);
                  week.ToDate = this.stringToDate(week.ToDate);
                });
              });
              return item;
            });
            resolve(retVal);
          }
        })
        .catch((error) => {
          reject(`error in PayRunService.getListByTeammemberId ->${error}`);
        });
    });
  }

  public static approveByTM(id: string): Promise<PayRun> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/payrun/approveByTM/${id}`, null)
        .then((result) => {
          resolve(result.data);
        })
        .catch((setError) => reject(setError));
    });
  }

  private static stringToDate(value: any): Date {
    return Utils.vsDateToDatetime(value);
  }
}
