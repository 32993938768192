<template>
  <div class="profile-dropdown" :class="{ 'is-open elevation-10': profileDropdown }" v-if="teamMemberInfo && teamMemberInfo.Email">
    <v-card-text class="pa-0 w-100" justify="end" align="right">
      <v-col class="pa-0">
        <NotificationDropDown
          :updateNotificationListFlag="updateNotificationListFlag"
          :userId="teamMemberInfo && teamMemberInfo.UserAccountsId"
          @errorRaise="errorRaise"
          @successRaise="successRaise"
          class="notification-drp"
        />
      </v-col>
      <v-col v-closeOnClick @click.prevent="TOGGLE_PROFILE_DROPDOWN()" class="heads pa-0">
        <div class="header">
          <div v-show="!hideName" class="header--title">
            <div class="user-name">
              <v-tooltip left>
                <template v-slot:activator="{ props }">
                  <span v-bind="props">
                    {{ teamMemberInfo.FirstName + " " + teamMemberInfo.LastName }}
                  </span>
                </template>
                {{ teamMemberInfo.FirstName + " " + teamMemberInfo.LastName }}
              </v-tooltip>
            </div>
          </div>
          <div v-if="teamMemberInfo.PhotoUrl">
            <v-img :src="teamMemberInfo.PhotoUrl" alt="profile-photo" class="profile-photo" />
          </div>
          <v-icon v-else :color="profileDropdown ? 'primary' : 'secondary'" class="profile-photo">mdi-account-circle</v-icon>
        </div>
        <div class="body" v-if="profileDropdown">
          <router-link to="/team-member/profile" class="menu-item email" v-if="profileDropdown">{{ teamMemberInfo.Email }}</router-link>
          <v-list class="pt-0 main-menu-items">
            <v-list-item v-if="commitments && commitments.length > 0" to="/team-member/timesheet" class="menu-item" title="Timesheet"/>
            <v-list-item to="/register/logout" class="menu-item" title="Log Out"/>
          </v-list>
        </div>
      </v-col>
    </v-card-text>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { TOGGLE_PROFILE_DROPDOWN, CLOSE_PROFILE_DROPDOWN } from "@/store/types";
import NotificationDropDown from "shared-components/src/components/Notification/NotificationDropDown.vue";
import store from "@/store";

export default defineComponent({
  props: ["teamMember", "commitments"],
  data() {
    return {
      loading: false,
      showDetailPopUp: false,
    };
  },
  components: {
    NotificationDropDown,
  },
  computed: {
    ...mapGetters(["userInfo", "teamMemberInfo", "profileDropdown", "updateNotificationListFlag"]),
    hideName() {
      return !this.profileDropdown && this.$vuetify.display.name === "xs";
    },
  },
  methods: {
    ...mapMutations([TOGGLE_PROFILE_DROPDOWN, CLOSE_PROFILE_DROPDOWN]),
    errorRaise(msg: any) {
      store.dispatch("showErrorMessage", msg)
    },
    successRaise(msg: any) {
      store.dispatch("showSuccessMessage", msg)
    },
  },
  directives: {
    closeOnClick: {
      beforeMount(el: any, binding: any, vnode: any) {
        document.addEventListener("click", (event) => onClick(event, el));
        function onClick(event: any, element: any) {
          const clickedELement = event.target;
          const container = element;
          let clickedItemSelector;
          if (clickedELement.classList.value !== "") {
            clickedItemSelector = "." + clickedELement.classList.value.split(" ").join(".");
          } else if (clickedELement.id) {
            clickedItemSelector = "#" + clickedELement.id;
          } else if (clickedELement.parentElement && clickedELement.parentElement.classList) {
            clickedItemSelector = "." + clickedELement.parentElement.classList.value.split(" ").join(".") + " " + clickedELement.tagName;
          }
          try {
            const isInContainer = container.querySelector(clickedItemSelector) != null;
            if (!isInContainer) {
              //vnode.context.CLOSE_PROFILE_DROPDOWN();
            }
          } catch (e) {
            //vnode.context.CLOSE_PROFILE_DROPDOWN();
          }
        }
      },
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
.notification-drp {
  top: 3px;
  right: 270px;
}
.notifBody {
  max-height: 562px;
  overflow-y: scroll;
}
.parentRow {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.info {
  margin: auto;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #006ce4;
  padding: 7px;
  text-align: center;
}
.info img {
  width: 8px;
}
.warning {
  margin: auto;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  text-align: center;
  background-color: #e9ad46;
  padding: 5px;
}
.warning img {
  width: 19px;
}
.detailLink {
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.detailLink .short-icon {
  font-size: 20px;
  margin-right: 5px;
}
.detailLink.short {
  font-size: 11px;
  margin-top: -20px;
  font-weight: normal;
}
.notifButtons {
  margin-top: 10px;
}
.readButton {
  width: 100%;
  height: 28px !important;
  font-size: 10px;
  background: #2e2e2e;
  border: 1px solid #dc1e28;
  color: #dc1e28;
  border-radius: 5px;
}
.detailButton {
  width: 100%;
  height: 28px !important;
  font-size: 10px;
  background: #dc1e28 !important;
  border: 1px solid #dc1e28;
  color: white;
  border-radius: 5px;
}
.gotoNotifList {
  text-align: center;
  margin-top: 5px;
}
.gotoNotifList a {
  text-decoration: none;
  color: #dc1e28;
}
.notifList h3 {
  color: white;
  width: 100%;
  padding: 10px;
}
.notifItems {
  position: relative;
  background-color: #282828;
  border-radius: 4px;
  margin-bottom: 2px;
}

.notifItems.clickNotif.not-seen {
  border-left: 6px solid red;
}

.notifItems:hover {
  background-color: #1f1e1e;
}

.notifItems span {
  color: white;
  font-size: 14px;
}
.shortText {
  display: flex;
  align-items: center;
}

.owner-label {
  font-size: 10px !important;
  font-weight: normal;
}
.shortText .action-icon {
  text-align: center;
  margin-top: -15px;
  cursor: pointer;
}

.shortText .action-icon .reply-counter-icon {
  top: 2px;
  cursor: pointer;
}
.shortText .action-icon .reply-counter-number {
  position: relative;
  color: #dc1e28;
  font-weight: bold;
  left: 15px;
  top: 0.5px;
  font-size: 10px;
  cursor: pointer;
}

.shortText .reply-item {
  background-color: #1a1818;
  color: #fff;
  font-size: 13px;
  padding: 5px 11px;
  border-radius: 0px 7px 7px 7px;
  margin-bottom: 2px;
  width: fit-content;
  min-width: 50%;
}

.shortText .reply-item.provider {
  float: inline-end;
  background-color: #3c3b3b !important;
  margin-top: 1px;
  border-radius: 7px 7px 0px 7px !important;
}
.newNotificationDropdown {
  background-color: #dc1e28;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  z-index: 9;
}
.notifItems .newNotification {
  left: -4px !important;
  top: 21px !important;
}
.notifIcon {
  margin-top: 2px;
  padding: 11px;
  float: left;
  border-radius: 5px;
  position: relative;
}
.notifIcon {
  &:hover {
    background-color: rgba($dark_gray, 0.1);
  }
}
.newNotification {
  position: absolute;
  background-color: #dc1e28;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  left: 23px;
  top: 14px;
  z-index: 9;
}

.notifList {
  max-height: 680px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  top: 50px;
  margin: 9px 10px 0 auto;
  color: black;
  transition: all 0.2s;
  width: 500px;
  min-width: 500px !important;
  &.is-open {
    background: $dark_gray;
  }
}
.batchLogo {
  width: 50px;
}
.inItem {
  position: relative;
  background-color: #dc1e28;
  height: 10px;
  width: 16px;
  border-radius: 8px;
}
.newNotification span {
  font-weight: bold;
  color: #dc1e28;
  position: absolute;
  top: -19px;
}
.profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: fixed;
  right: 0;
  top: 0;
  margin: 9px 10px 0 auto;
  color: black;
  transition: all 0.2s;
  height: 68px;
  max-height: 68px;
  width: 250px;
  &.is-open {
    background: $dark_gray;
    min-height: 210px;
    max-height: 248px;
    .header {
      width: 100%;
      margin: 10px 5px 0 0;
      .profile-photo {
        width: 60px;
        height: 60px;
        font-size: 60px;
      }
      .user-name {
        font-size: 16px;
        color: white;
      }
    }
  }
  &:not(.is-open) {
    .header {
      &:hover {
        background-color: rgba($dark_gray, 0.1);
      }
    }
  }
  .header {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    .header--title {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-right: 10px;
    }
    .profile-photo {
      width: 30px;
      height: 30px;
      font-size: 30px;
      border-radius: 50%;
      transition: all 0.2s;
    }
    .user-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $text_color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    width: 100%;
    .menu-item {
      width: 100%;
      text-align: center;
      padding: 5px;
      font-size: 14px;
      font-weight: 600;
      color: white;
      cursor: pointer;
      transition: all 0.2s;
      text-decoration: none;
    }
    .email {
      background-color: $racecar;
      color: white;
      padding: 15px 5px;
      &:hover {
        background-color: $racecar;
      }
    }
  }
}

@media (max-width: 600px) {
  .header {
    width: 70px;
  }
  .notifIcon {
    left: 130px;
  }
}
@media (max-width: 530px) {
  .notifList {
    min-width: 250px !important;
  }
}

@media only screen and (max-width: 600px) {
  .notification-drp {
    right: 50px;
  }
}
</style>
