import { firestore } from "shared-components/src/plugins/firebase";
import { CollectionsNames } from "shared-components/src/definitions/constants";
import {
  Teammember,
  TeammemberSkill,
  Education,
  Certificate,
  LanguageSkill,
  Address,
  Phone,
  ProfessionalDetail,
  ProfessionalDetailCareerObjectiveModel,
} from "shared-components/src/models/Teammember";
import { Contact } from "shared-components/src/models/Contact";
import { PersonalDetailModel } from "shared-components/src/models/PersonalDetailModel";
import RecaptchaResponse from "shared-components/src/models/RecaptchaResponse";
import Commitment, { UICommitment } from "shared-components/src/models/Commitment";
import ApiService from "./ApiService";
import Utils from "shared-components/src/utils/Utils";
import { SystemErrors } from "shared-components/src/definitions/systemErrors";

export interface VerificationResponse {
  StartTimerDate: Date | null;
  EndTimerDate: Date | null;
  VerifiedOn: Date | null;
  WrongCodeInputCount: number;
}

export default class TeamMemberService {
  public static create(model: Teammember, isSignUp = false): Promise<Teammember> {
    return new Promise((resolve, reject) => {
      const url = isSignUp ? "signup" : "create";
      if (!isSignUp) {
        model.BirthDate = Utils.addTimezoneOffset(model.BirthDate);
      }
      ApiService.post(`/teammember/${url}`, model)
        .then((res) => {
          model.Id = res.data;
          resolve(model);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static savePersonalDetail(model: PersonalDetailModel): Promise<PersonalDetailModel | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/personalDetail`, model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static getExperienceDetail(): Promise<ProfessionalDetail | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/experienceDetail`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value as ProfessionalDetail);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static saveExperienceDetail(model: ProfessionalDetail): Promise<ProfessionalDetail | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/experienceDetail`, model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static saveExperienceCareerObjectiveDetail(
    model: ProfessionalDetailCareerObjectiveModel
  ): Promise<ProfessionalDetailCareerObjectiveModel | null> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/experienceCareerObjectiveDetail`, model)
        .then((res) => {
          resolve(res.data.value);
        })
        .catch((err) => {
          resolve(err.data.value);
        });
    });
  }

  public static removeExperienceCareerObjectiveDetail(id: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/experienceCareerObjectiveDetail/${id}`)
        .then((res) => {
          resolve(res.data.value);
        })
        .catch((err) => {
          reject(err.data.value);
        });
    });
  }

  public static removeSkill(skillId: any): Promise<TeammemberSkill[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/removeSkill/${skillId}`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static updateSkill(skillModel: any): Promise<TeammemberSkill[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/saveSkill`, skillModel)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getContact(): Promise<Contact | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/contact`, "")
        .then((res) => {
          resolve(this.getContactResponse(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static setAddressAsDefault(addressId: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/address/setAsDefault/${addressId}`, null)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static removeAddress(addressId: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/address/${addressId}`)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static saveAddress(address: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/address`, address)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static sendAddressCode(id: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/sendAddressVerificationCode/${id}`, null)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static verifyAddress(address: any): Promise<Address[]> {
    return new Promise((resolve, reject) => {
      var verificationCode = address.VerificationCode;
      ApiService.post(`/teammember/verifyAddress/${address.Id}`, { verificationCode })
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static setPhoneAsDefault(phoneId: any): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/phone/setAsDefault/${phoneId}`, null)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static removePhone(phoneId: any): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/phone/${phoneId}`)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static savePhone(phone: any): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/phone`, phone)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static saveRoleAndTitle(details: any): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/roleAndTitle`, details)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static sendPhoneCode(id: any): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/sendPhoneVerificationCode/${id}`, null)
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static verifyPhone(phone: Phone): Promise<Phone[]> {
    return new Promise((resolve, reject) => {
      var verificationCode = phone.VerificationCode;
      ApiService.post(`/teammember/verifyPhone/${phone.Id}`, { verificationCode })
        .then((res) => {
          resolve(this.getVerificationResponseList(res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static getById(id: string): Promise<Teammember | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/GetById/${id}`, "")
        .then((tmResult) => {
          if (tmResult.data) {
            resolve({ Id: tmResult.data.id, ...tmResult.data } as Teammember);
          } else {
            resolve(null);
          }
        })
        .catch((tmError) => reject(`error in TeamMemberService.getById -> ${tmError}`));
    });
  }

  public static getTeammember(uid: string | undefined): Promise<Teammember | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/GetTeammemberByUid/${uid}`, "")
        .then((result) => {
          if (!result.data) {
            resolve(null);
          } else {
            var model = this.getTeammemberResponse(result.data);
            resolve(model);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static GetTeammemberWithProfessional(): Promise<Teammember | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/GetTeammemberWithProfessional`, "")
        .then((result) => {
          if (!result.data) {
            resolve(null);
          } else {
            var model = this.getTeammemberResponse(result.data);
            resolve(model);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getTeammemberSummary(): Promise<Teammember | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/GetTeammemberSummaryByUid`, "")
        .then((result) => {
          if (!result.data) {
            resolve(null);
          } else {
            var model = this.getTeammemberResponse(result.data);
            resolve(model);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getTeammemberByEmail(email: string): Promise<Teammember | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/GetTeammemberByEmail/${email}`, "")
        .then((result) => {
          if (!result.data) {
            resolve(null);
          } else {
            resolve({ ...result.data } as Teammember);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async UpdateRepo(model: Teammember): Promise<Teammember> {
    if (!model.Id) {
      model.Id = firestore.collection(CollectionsNames.Teammember).doc().id;
    }
    const repositoryModel = {
      Repositories: model.Repositories,
    };
    return new Promise((resolve, reject) => {
      firestore
        .collection(CollectionsNames.Projects)
        .doc(model.Id)
        .update(repositoryModel)
        .then((addResult: any) => {
          resolve(model);
        })
        .catch((addError: any) => reject(addError));
    });
  }
  public static update(model: Teammember): Promise<boolean> {
    return new Promise((resolve, reject) => {
      firestore
        .collection(CollectionsNames.Teammember)
        .doc(model.Id)
        .set(model, { merge: true })
        .then((result: any) => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(false);
        });
    });
  }

  public static getActiveTeammembersByLinemanager(linemanagerId: any): Promise<Teammember[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/activeTeammembers/${linemanagerId}`, "")
        .then((tmResult) => {
          if (!tmResult.data) {
            resolve([]);
          } else {
            const retVal = tmResult.data.map((d: any) => {
              const tm = { Id: d.id, ...d } as Teammember;
              tm.FullName = `${tm.FirstName} ${tm.LastName}`;
              return tm;
            });
            resolve(retVal);
          }
        })
        .catch((tmError) => reject("Team member error => " + tmError));
    });
  }

  public static getList(): Promise<Teammember[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember`, "")
        .then((tmResult) => {
          if (!tmResult.data) {
            resolve([]);
          } else {
            const retVal = tmResult.data.map((d: any) => {
              const tm = { Id: d.id, ...d } as Teammember;
              tm.FullName = `${tm.FirstName || ""} ${tm.LastName || ""}`;
              return tm;
            });
            resolve(retVal);
          }
        })
        .catch((tmError) => reject("Team member error => " + tmError));
    });
  }

  public static async verifyCaptcha(captchaToken: any): Promise<RecaptchaResponse> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/teammember/verifyCaptcha`, { captchaToken: captchaToken })
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve({} as RecaptchaResponse);
          } else {
            const result = { ...res.data.value } as RecaptchaResponse;
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static async getCommitmentsByTeammeberId(addExtraDetail: boolean = false): Promise<UICommitment[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/GetCommitmentByTeammemberId`, `?addExtraDetail=${addExtraDetail}`)
        .then((result) => {
          if (!result.data || result.data.statusCode != SystemErrors.Authentication.Success || !result.data.value) {
            resolve([] as UICommitment[]);
          } else {
            const retVal = [] as UICommitment[];
            result.data.value.forEach((doc: any) => {
              const item = { id: doc.id, ...doc } as UICommitment;
              if (doc.EndDate) {
                item.EndDate = Utils.vsDateToDatetime(doc.EndDate);
              }
              if (doc.StartDate) {
                item.StartDate = Utils.vsDateToDatetime(doc.StartDate);
              }
              retVal.push(item);
            });
            resolve(retVal);
          }
        })
        .catch((error) => {
          reject(`error in TeamMemberService.getCommitmentsByTeammeberId ->${error}`);
        });
    });
  }

  public static getTeamMembersOfCustomer(customerId: string): Promise<Array<{ id: string; Name: string }>> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/commitment/GetCommitmentByCustomerId/${customerId}`, "")
        .then((commitmentResult) => {
          if (!commitmentResult.data) {
            resolve([]);
          } else {
            const retVal = [] as Array<{ id: string; Name: string }>;
            commitmentResult.data
              .map((d: any) => {
                const commitment = { id: d.id, ...d } as Commitment;
                return commitment.TeamMembers;
              })
              .forEach((tms: any) => {
                tms.forEach((tm: any) => {
                  if (retVal.filter((x) => x.id === tm.id).length === 0) {
                    retVal.push({ id: tm.id, Name: tm.TmFullName });
                  }
                });
              });
            resolve(retVal);
          }
        })
        .catch((commitmentError) => reject("commitmentError => " + commitmentError));
    });
  }
  public static uploadCertificateCv(certificateId: any, file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.upload(`/teammember/uploadCertificateCv/${certificateId}`, file)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static uploadSkillCv(skillId: any, file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.upload(`/teammember/uploadSkillCv/${skillId}`, file)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static uploadResume(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.upload(`/teammember/uploadProfileCv`, file)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getSkillDownloadLink(skillId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/downloadSkillCv/${skillId}`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getCertificateDownloadLink(certificateId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/downloadCertificateCv/${certificateId}`)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getResumeDownloadLink(): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/downloadProfileCv`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve("");
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static removeResume(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/removeCv`)
        .then((res) => {
          if (!res.data || res.status != SystemErrors.Authentication.Success) {
            resolve(false);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static getLanguageSkills(): Promise<LanguageSkill[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/languageSkills`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value as LanguageSkill[]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async setLanguageSkill(model: LanguageSkill): Promise<LanguageSkill[]> {
    return new Promise((resolve, reject) => {
      ApiService.post("/teammember/saveLanguageSkill", model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as LanguageSkill[]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async deleteLanguageSkill(id: any): Promise<LanguageSkill[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/removeLanguageSkill/${id}`)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as LanguageSkill[]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getPersonalDetail(): Promise<Teammember | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/personalDetails`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            var model = this.getTeammemberResponse(res.data.value);
            resolve(model);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getEducations(): Promise<Education[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/educations`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            var response = res.data.value;
            response.forEach((item: any) => {
              if (item.StartDate) {
                item.StartDate = new Date(item.StartDate);
              }
              if (item.EndDate) {
                item.EndDate = new Date(item.EndDate);
              }
            });
            resolve(response as Education[]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async setEducation(model: Education): Promise<Education[]> {
    return new Promise((resolve, reject) => {
      var startDate = Utils.addTimezoneOffsetToStartDate(model.StartDate);
      var endDate = Utils.addTimezoneOffsetToEndDate(model.EndDate);

      if (startDate) {
        model.StartDate = startDate;
      }
      if (endDate) {
        model.EndDate = endDate;
      }
      ApiService.post("/teammember/saveEducation", model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as Education[]);
          } else {
            var retval = this.getDataFromResponse(res.data.value) as Education[];
            resolve(retval);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async deleteEducation(id: any): Promise<Education[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/removeEducation/${id}`)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as Education[]);
          } else {
            var retval = this.getDataFromResponse(res.data.value) as Education[];
            resolve(retval);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  public static getCertificates(): Promise<Certificate[] | null> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/teammember/certificates`, "")
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve(null);
          } else {
            resolve(res.data.value as Certificate[]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async setCertificate(model: Certificate): Promise<Certificate> {
    return new Promise((resolve, reject) => {
      ApiService.post("/teammember/saveCertificate", model)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve({} as Certificate);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static async deleteCertificate(id: any): Promise<Certificate[]> {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/teammember/removeCertificate/${id}`)
        .then((res) => {
          if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
            resolve([] as Certificate[]);
          } else {
            resolve(res.data.value);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private static getTeammemberResponse(result: any) {
    var item = { ...result } as Teammember;
    item.BirthDate = new Date(result.BirthDate);
    if (item.UpdatedPaymentMethodDate) {
      item.UpdatedPaymentMethodDate = new Date(result.UpdatedPaymentMethodDate);
    }
    item.Educations?.forEach((item) => {
      if (item.StartDate) {
        item.StartDate = new Date(item.StartDate);
      }
      if (item.EndDate) {
        item.EndDate = new Date(item.EndDate);
      }
    });
    return { ...result } as Teammember;
  }

  private static getContactResponse(res: any) {
    if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
      return [];
    } else {
      res.data.value.Addresses = this.convertResponse(res.data.value.Addresses);
      res.data.value.PhoneNumbers = this.convertResponse(res.data.value.PhoneNumbers);
      return res.data.value;
    }
  }
  private static getVerificationResponseList(res: any) {
    if (!res.data || res.data.statusCode != SystemErrors.Authentication.Success || !res.data.value) {
      return [];
    } else {
      var result = this.convertResponse(res.data.value);
      return result;
    }
  }
  private static convertResponse(result: any) {
    if (result && result.length > 0) {
      result.forEach((c: any) => {
        if (c) {
          if (c.StartTimerDate) {
            c.StartTimerDate = new Date(c.StartTimerDate);
          }
          if (c.EndTimerDate) {
            c.EndTimerDate = new Date(c.EndTimerDate);
          }
          if (c.VerifiedOn) {
            c.VerifiedOn = new Date(c.VerifiedOn);
          }
        }
      });
      return result;
    }
  }

  private static getDataFromResponse(list: any) {
    const retVal = list.map((doc: any) => {
      const item = { id: doc.Id, ...doc };
      if (item.EndDate && doc.EndDate) {
        item.EndDate = new Date(doc.EndDate);
        item.EndDate = Utils.removeTimezoneOffset(item.EndDate);
      }
      if (item.StartDate) {
        item.StartDate = new Date(doc.StartDate);
        item.StartDate = Utils.removeTimezoneOffset(item.StartDate);
      }
      return item;
    });

    return retVal;
  }
}
